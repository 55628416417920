import routes from '../constants/routes'

export const halo = {
  title: `Thank you`,
  root: `../../`,
  route: routes.terms,
}

export const intro = {
  ...halo,
}
