/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

import successIcon from '../assets/icons/success.svg'

interface ThankYouProps {
  containerStyles?: any
}

const ThankYou: FC<ThankYouProps> = ({ containerStyles }) => {
  return (
    <section sx={containerStyles}>
      <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, pt: 4, px: 4, pb: 5 }}>
        <div sx={{ maxWidth: `75ch` }}>
          <img src={successIcon} alt="" sx={{ width: 80, mb: 4, ml: -1, display: `block` }} />
          <h1 sx={{ mt: 0, fontSize: 5 }}>Thanks for your interest in CartaX!</h1>
          <p sx={{ mb: 5, fontSize: `1.125rem` }}>
            Our team will reach out soon to discuss how we can help you learn more about CartaX and how we’re bringing
            liquidity to the private markets.
          </p>
        </div>
      </div>
    </section>
  )
}

export default ThankYou
